export default {
    configName: 'lfl36',
    appTitle: 'ЛФЛ WORLD Воронеж | LFL36.RU',
    federation: {
        _id: '6246dd6b1fdbc63aa35507ca'
    },
    bannerNode: {
        media: `${process.env.FILES_BUCKET}storage/banner_36.jpg`,
        publicationId: null,
        externalLink: 'https://vk.com/lflvoronezh'
    },
    brandEmblem: `${process.env.FILES_BUCKET}apps/voronezh_260.png`,
    topBanners: [
        `${process.env.FILES_BUCKET}apps_promo/lfl36/1.png`,
        `${process.env.FILES_BUCKET}apps_promo/lfl36/2.png`,
        `${process.env.FILES_BUCKET}apps_promo/lfl36/3.png`,
        `${process.env.FILES_BUCKET}apps_promo/lfl36/5.png`,
        `${process.env.FILES_BUCKET}apps_promo/lfl36/9.png`,
        `${process.env.FILES_BUCKET}apps_promo/lfl36/10.png`
    ],
    topLinks: [
        'https://vk.com/don_atlant',
        'https://vk.com/magazinturnir',
        'https://vk.com/sc_metallist',
        'https://vk.com/fifauefavrn',
        'https://oceanarium-vrn.ru',
        'https://vk.com/dodo_voronezh'
    ],
    footerCreds: {
        copyright: '© АНФСО Любительская Футбольная Лига Воронеж, 2022 - 2024',
        phone: '+7 (903) 652 42 97',
        address: '394002, г. Воронеж, улица Ржевская, дом 11'
    },
    footerStores: null,
    footerBanners: [
        `https://files.amateum.com/storage/0959a099-8c3f-48fd-af19-e6d43dec7b6f.png`,
        `${process.env.FILES_BUCKET}apps_promo/lfl36/11.png`,
        `${process.env.FILES_BUCKET}apps_promo/lfl36/7.png`,
        `${process.env.FILES_BUCKET}apps_promo/lfl36/8.png`,
    ],
    footerLinks: [
        'https://tooba.com',
        'https://npk-kanzler.ru',
        'https://bestpark-vrn.ru',
        'https://www.cityparkgrad.ru/razvlecheniya/bouling'
    ],
    footerSocials: {
        vk: 'https://vk.com/lflvoronezh',
        telegram: 'https://t.me/lfl_voronezh',
        youtube: 'https://youtube.com/@LFLVoronezh'
    },
    fantasyBrand: {
        type: 'label',
        text: 'Amateum',
        link: null
    },
    metrikaCounter: 89111502,
    firebase: {
        config: {
          apiKey: "AIzaSyAeNtnfS8zOr8wKcOWNi8sRzGfojTe6a_0",
          authDomain: "amateum-bb5a1.firebaseapp.com",
          projectId: "amateum-bb5a1",
          storageBucket: "amateum-bb5a1.appspot.com",
          messagingSenderId: "456338694560",
          appId: "1:456338694560:web:87830ca76300208a4784c5",
          measurementId: "G-7HY1ZRCYFJ"
        },
        vapid: 'BBSkT_vcHGt2nxpZathVaRcJp5Q73gimRpH-njPUWOE2P_eUXKN-HN4pyl_McYV5f3LUgd43-jvP8SbZVWF1dRE'
    }
}
